import { Link } from "react-router-dom";

//COMPONENTS
import LandingNavBar from "../components/landig_navbar";
import Banner from "../components/landing_banner";
import Grid from "../components/grid";
import LandingCard from "../components/landing_card";
import CideinContainer from "../components/general_section";
import CideinFooter from "../components/cidein_footer";
import MwMockImage from '../assets/img/mock_image.png';

//INFO
import CardsInfo from "../assets/info_json/cards.json";
import BusinessValues from "../assets/info_json/businessValues.json";
import BusinessCard from "../components/business_cards";
import ServicesCard from "../components/servicesCard";
import Carousel from "../components/carousel";
import Slides from "../components/slides";

function Home() {
  return (
    <>
      <LandingNavBar />
      <Banner />
      <Grid columns={{sm: "3", md: "3", lg: "3", gl: "1", xsm: "1"}} gap={{ sm: "0", md: "0", lg: "0", gl:"0" }}>
        {CardsInfo.map((cardInfo) => {
          return (
            <LandingCard
              title={cardInfo.title}
              icon={cardInfo.icon}
              description={cardInfo.description}
              theme={cardInfo.theme}
              key={cardInfo.title}
            />
          );
        })}
      </Grid>
      <CideinContainer>
      <Grid columns={{sm: "3", md: "3", lg: "3", gl: "1", xsm:"1"}} gap={{ sm: "32", md: "48", lg: "48", gl:"32" }}>
      {
          BusinessValues.map((businessCard,index )=>{
            return(<BusinessCard title={businessCard.title} text={businessCard.text} icon={businessCard.icon} theme={businessCard.theme} key={index}/>)
          })
        }
      </Grid>

      </CideinContainer>
      <CideinContainer>
      <h4 className="txt_primary landing_title">NUESTROS SERVICIOS</h4>
      <Grid columns={{sm: "1", md: "1", lg: "1", gl: "1", xsm:"1"}} gap={{ sm: "24", md: "48", lg: "48", gl:"32" }}>
        
        
        <ServicesCard text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." title="INTERVENTORÍA" image={MwMockImage} isReversed=" " theme="primary_theme"/>
        <ServicesCard text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." title="CONSULTORÍA" image={MwMockImage} isReversed="reversed" theme="secondary_theme"/>
        <ServicesCard text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat." title="CONSTRUCCIÓN OBRA CIVIL" image={MwMockImage} isReversed=" " theme="primary_theme"/>
        
      </Grid>
        
      </CideinContainer>
      <CideinContainer>
      <h4 className="txt_primary landing_title">CONOCE NUESTRO TRABAJO</h4>
      <Slides />
      </CideinContainer>
      <CideinFooter />
    </>
  );
}

export default Home;
