//IMAGES
import { Link } from "react-router-dom";
import BannerImage from "../assets/img/banner_1.png";
function Banner() {
  return (
    <div id="banner">
      <img src={BannerImage} alt="" />
      <div id="over_banner_info">
        <h4 className="txt_white banner_title">MW INGENIERIA SAS</h4>
        <p className="txt_white banner_text">
        Somos una empresa comprometida con la construcción de obras civiles, enmarcada en la mejora continua, fieles a nuestros valores garantizando los recursos necesarios para el cumplimiento de nuestros proyectos.
        </p>
        <Link className="btn primary_theme" to={"/nuestros-proyectos"}>Portafolio</Link>
      </div>
    </div>
  );
}

export default Banner;
