import MwMockImage from '../assets/img/mock_image.png';

interface ServicesCardOptions {
    title: string,
    text: string,
    image: string,
    isReversed: string,
    theme: string
}

export default function ServicesCard({title, text, image, isReversed, theme}:ServicesCardOptions){
    return(
        <div className={`servicesCard ${theme}`}>
          <div className={`servicesCard_body ${isReversed}`}>
          <div className="servicesCard_left">
              <div className="servicesCard_info">
                <p className="servicesCard_title">{title}</p>
                <p className="servicesCard_text">{text}</p>
              </div>
          </div>
          <div className="servicesCard_right">
            <div className="services_image">
              <img src={image} alt="MW" />
            </div>
          </div>
          </div>
        </div>
    )
}