
type HeaderTitle = {
    image: string;
    text: string;
}

function HeaderTitle({image, text}:HeaderTitle) {
  return (
    <div id="banner">
      <img src={image} alt="" />
      <div id="over_banner_info">
        <h4 className="txt_white banner_title">{text}</h4>
      </div>
    </div>
  );
}

export default HeaderTitle;
