import { useEffect, useRef, useState } from "react";

type ImageCarouselProps = {
  gallery: string[]
}

export default function ImageCarousel(props:ImageCarouselProps) {
  const viewBox = useRef<HTMLDivElement>(null);
  const slidesContainer = useRef<HTMLDivElement>(null);

  const [viewboxWidth, setViewboxWidth] = useState(1024);
  const [numberOfSlides, setNumberOfSlides] = useState(4);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleTile = (action: string) => {
    if (action === "next") {
      setScrollPosition((prevTile) => prevTile + viewboxWidth);
    }
    if (action === "back") {
      setScrollPosition((prevTile) => prevTile - viewboxWidth);
    }
    if (scrollPosition > viewboxWidth * numberOfSlides) {
      setScrollPosition(0);
    }
    if (scrollPosition < 0) {
      setScrollPosition(0);
    }
  };

  useEffect(()=>{
    if (slidesContainer.current !== null) {
      const slidesChildren = slidesContainer.current.offsetWidth;
      const slides = slidesChildren
      console.log(slides)
      // setNumberOfSlides(slides)
    }
  },[slidesContainer])

  useEffect(() => {
    if (viewBox.current !== null) {
      const DOMviewboxWidth = viewBox.current.offsetWidth;
      viewBox.current.scroll({
        left: scrollPosition,
      });
      setViewboxWidth(DOMviewboxWidth);
    }
  }, [viewBox, scrollPosition]);

  return (
    <div className="slider">
      <div className="carousel_controller" onClick={() => handleTile("back")}>
        <span className="material-symbols-outlined">arrow_back_ios</span>
      </div>
      <div className="slides_viewbox" ref={viewBox}>
        <div className="slides_container" ref={slidesContainer}>
          {props.gallery.map(image=>{
            return(
              <div className="tile" style={{ width: viewboxWidth }}>
            <div className="carouselCard">
              <div className="carouselCard_body">
                <div className="image_container">
                  <div className="only_images">
                    <img src={image} alt="Interventoria" />
                  </div>
                </div>
              </div>
            </div>
          </div>
            )
          })}
        </div>
      </div>
      <div className="carousel_controller" onClick={() => handleTile("next")}>
        <span className="material-symbols-outlined">arrow_forward_ios</span>
      </div>
    </div>
  );
}
