import mwlogo from "../assets/img/mw_logo.png";
import footerImage from "../assets/img/footer.png";

function CideinFooter() {
  return (
    <footer
      className="mw_footer"
      style={{ backgroundImage: `url('${footerImage}')` }}
    >
      <div className="layer">
        <div className="flag_container">
          <div className="flag_image">
            <img src={mwlogo} alt="MW INGENIERIA SAS" />
          </div>
          <div className="flag_text">MW INGENIERÍA SAS</div>
        </div>
        <div className="contact_info">
          <h1>CONTÁCTANOS</h1>
          <ul>
            <li>
              <div className="contact_item">
                <div className="contact_icon">
                  <span className="material-symbols-outlined">call</span>
                </div>
                <div className="contact_info">+57 302 4331864</div>
              </div>
            </li>
            <li>
              <div className="contact_item">
                <div className="contact_icon">
                  <span className="material-symbols-outlined">mail</span>
                </div>
                <div className="contact_info">mwingenieriasas@gmail.com</div>
              </div>
            </li>
            <li>
              <div className="contact_item">
                <div className="contact_icon">
                  <span className="material-symbols-outlined">whatshot</span>
                </div>
                <div className="contact_info">+57 302 4331864</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default CideinFooter;
