interface BusinessCardOptions {
  theme: string;
  title: string;
  text: string;
  icon: string;
}
export default function BusinessCard({
  theme,
  title,
  text,
  icon,
}: BusinessCardOptions) {
  return (
    <div className={`businessCard ${theme}`}>
      <div className="businessCard_body">
        <div className="businessCard_icon">
          <span className="material-symbols-outlined">{icon}</span>
        </div>
        <div className="businessCard_title">
          <p>{title}</p>
        </div>
        <div className="businessCard_text">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
}
