type GridSettings = {
  children: React.ReactNode;
  columns: {
    gl: string, //general
    xsm: string, //extra small
    sm: string, //small
    md: string, //medium
    lg: string //large
  };
  gap: { 
    sm: string,
    md: string,
    lg: string,
    gl: string,
  };
};

function Grid({ children, columns, gap }: GridSettings) {
  return (
    <div
      className={`grid col_${columns.gl} col_sm_${columns.sm} col_md_${columns.md} col_lg_${columns.lg} gap_sm_${gap.sm} gap_md_${gap.md} gap_lg_${gap.lg} gap_${gap.gl}`}
    >
      {children}
    </div>
  );
}

export default Grid;
