import mwlogo from "../assets/img/mw_logo.png";

function LandingNavBar() {
  return (
    <nav id="landing_navbar">
      <div id="top_logo_name">
        <img id="logo" src={mwlogo} alt="" />
        <p id="landing_nav_title">MW INGENIERÍA SAS</p>
      </div>
      <div id="bottom_nav">
        <ul>
          <li>
            <a href="#/">Inicio</a>
          </li>
          <li>
            <a href="#/nuestros-proyectos">Portafolio</a>
          </li>
          <li>
            <a href="#/sobre-nosotros">Sobre nosotros</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default LandingNavBar;
