import { Link } from "react-router-dom";

//COMPONENTS
import LandingNavBar from "../components/landig_navbar";
import CideinContainer from "../components/general_section";
import CideinFooter from "../components/cidein_footer";

//Nuestros proyectos

import Grid from "../components/grid";
import MwProjectsList from "../components/mwProjectsList";
import Slides from "../components/slides";
import ImageCarousel from "../components/imageCarousel";

function OurProjects() {
  return (
    <>
      <LandingNavBar />
        <CideinContainer>
            <h2>PROYECTOS EJECTUTADOS</h2>
            <Grid columns={{sm: "3", md: "3", lg: "3", gl: "1", xsm: "1"}} gap={{ sm: "0", md: "0", lg: "0", gl:"0" }}>
                <MwProjectsList/>
                {/* <div>
                    <div className="mw_show_projects">
                        <h3>Obras civiles para cimentación de tanques gas natural.</h3>
      <p>Funciones: Contratista constructor.</p>
      <p>Fecha: Noviembre de 2013 a diciembre de 2013.</p>
      <p>Cliente: SCHOTT ENVASES FARMACEUTICOS</p>
      <ImageCarousel/>
                    </div>
                </div> */}
            </Grid>
        </CideinContainer>
      <CideinFooter />
    </>
  );
}

export default OurProjects;
