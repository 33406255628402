import { Link } from "react-router-dom";
import NuestrosProyectos from "../assets/info_json/ourProjects.json";

export default function MwProjectsList() {
  return (
    <div>
      <ul className="mw_index">
        {NuestrosProyectos.map((mwProjects) => {
          return (
            <li>
              <Link to={mwProjects.link}>{mwProjects.title}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
