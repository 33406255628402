import { useEffect, useRef, useState } from "react";
import MwMockImage from "../assets/img/mock_image.png";

export default function Slides() {
  const viewBox = useRef<HTMLDivElement>(null);
  const [viewboxWidth, setViewboxWidth] = useState(2048);
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleTile = (action: string) => {
    const numberOfSlides = 2;
    if(action === "next") {
        setScrollPosition(prevTile=>prevTile+viewboxWidth)
    }
    if(action ==="back"){
        setScrollPosition(prevTile=>prevTile-viewboxWidth)
    }
    if(scrollPosition>viewboxWidth*numberOfSlides){
        setScrollPosition(0)
    }
    if(scrollPosition<0){
        setScrollPosition(0)
    }

  }

  window.addEventListener("load", evt => {
    if (viewBox.current !== null) {
      const DOMviewboxWidth = viewBox.current.offsetWidth;
      viewBox.current.scroll({
        left: scrollPosition,
      });
      setViewboxWidth(DOMviewboxWidth);
    }
  })

  window.addEventListener("resize", evt => {
    if (viewBox.current !== null) {
      const DOMviewboxWidth = viewBox.current.offsetWidth;
      viewBox.current.scroll({
        left: scrollPosition,
      });
      setViewboxWidth(DOMviewboxWidth);
    }
  })
  
  useEffect(() => {
    if (viewBox.current !== null) {
      const DOMviewboxWidth = viewBox.current.offsetWidth;
      viewBox.current.scroll({
        left: scrollPosition,
      });
      setViewboxWidth(DOMviewboxWidth);
    }
  }, [viewBox, scrollPosition]);
  return (
    <div className="slider">
      <div className="carousel_controller" onClick={()=>handleTile("back")}>
        <span className="material-symbols-outlined">arrow_back_ios</span>
      </div>
      <div className="slides_viewbox" ref={viewBox}>
        <div className="slides_container">
          <div className="tile" id="1" style={{ width: viewboxWidth }}>
            <div className="carouselCard">
              <div className="carouselCard_body">
                <div className="carouselCard_left">
                  <div className="carouselCard_info">
                    <h6 className="carousel_title">Obras civiles para cimentación de tanques gas natural. ​</h6>
                    <p className="carousel_projectInfo">
                      Localización: Cartagena, Bolivar.
                    </p>
                    <p className="carousel_projectInfo">
                      Función realizada: Contratista Constructor.
                    </p>
                    <p className="carousel_projectInfo">
                      Fecha de ejecución: Noviembre de 2013 / Diciembre de 2013
                    </p>
                    <button className="btn primary_theme">Ver más</button>
                  </div>
                </div>
                <div className="carouselCard_right">
                  <div className="image_container">
                    <img src={MwMockImage} alt="Interventoria" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile" id="2" style={{ width: viewboxWidth }}>
            <div className="carouselCard">
              <div className="carouselCard_body">
                <div className="carouselCard_left">
                  <div className="carouselCard_info">
                    <h6 className="carousel_title">Extracción de tanques, remediación, instalación de tanques y remodelación de EDS La TEXANA.</h6>
                    <p className="carousel_projectInfo">
                      Localización: Cartagena, Bolivar.
                    </p>
                    <p className="carousel_projectInfo">
                      Función realizada: Contratista Constructor.
                    </p>
                    <p className="carousel_projectInfo">
                      Fecha de ejecución: Febrero de 2014 / Julio de 2014.
                    </p>
                    <button className="btn primary_theme">Ver más</button>
                  </div>
                </div>
                <div className="carouselCard_right">
                  <div className="image_container">
                    <img src={MwMockImage} alt="Interventoria" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile" id="3" style={{ width: viewboxWidth }}>
            <div className="carouselCard">
              <div className="carouselCard_body">
                <div className="carouselCard_left">
                  <div className="carouselCard_info">
                    <h6 className="carousel_title">Construcción de estación de servicio Mulera ESSO</h6>
                    <p className="carousel_projectInfo">
                      Localización: Cartagena, Bolivar.
                    </p>
                    <p className="carousel_projectInfo">
                      Función realizada: Contratista Constructor.
                    </p>
                    <p className="carousel_projectInfo">
                      Fecha de ejecución: Noviembre de 2014 / Diciembre de 2017.
                    </p>
                    <button className="btn primary_theme">Ver más</button>
                  </div>
                </div>
                <div className="carouselCard_right">
                  <div className="image_container">
                    <img src={MwMockImage} alt="Interventoria" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile" id="4" style={{ width: viewboxWidth }}>
            <div className="carouselCard">
              <div className="carouselCard_body">
                <div className="carouselCard_left">
                  <div className="carouselCard_info">
                    <h6 className="carousel_title"> Obras civiles para cimentación de tanques gas natural</h6>
                    <p className="carousel_projectInfo">
                      Localización: Cartagena, Bolivar.
                    </p>
                    <p className="carousel_projectInfo">
                      Función realizada: Contratista Constructor.
                    </p>
                    <p className="carousel_projectInfo">
                      Fecha de ejecución: Mayo de 2022 / Junio de 2022
                    </p>
                    <button className="btn primary_theme">Ver más</button>
                  </div>
                </div>
                <div className="carouselCard_right">
                  <div className="image_container">
                    <img src={MwMockImage} alt="Interventoria" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="carousel_controller"  onClick={()=>handleTile("next")}>
        <span className="material-symbols-outlined">arrow_forward_ios</span>
      </div>
    </div>
  );
}
