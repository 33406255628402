import { Link, Route, Routes } from "react-router-dom";
//PAGES
import Home from "../src/pages/index";
import DetailedProjects from "./pages/detalleProyectos";
import OurProjects from "./pages/nuestrosProyectos";
import SobreNosotros from "./pages/sobreNosotros";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="sobre-nosotros" element={<SobreNosotros />} />
        <Route path="nuestros-proyectos" element={<OurProjects />} />
        <Route path="/proyectos/:project_id" element={<DetailedProjects />} />
      </Routes>
    </div>
  );
}

export default App;
