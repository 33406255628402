type ValuesCardProps = {
    title: string,
    children: React.ReactNode,
    theme: string
}
export default function ValuesCard({title, children, theme}: ValuesCardProps){
    return (
        <div className={`values_card_container ${theme}`}>
            <h6>{title}</h6>
            {children}
        </div>
    )
}