import { Link, useParams } from "react-router-dom";

//COMPONENTS
import LandingNavBar from "../components/landig_navbar";
import CideinContainer from "../components/general_section";
import CideinFooter from "../components/cidein_footer";

//Nuestros proyectos

import Grid from "../components/grid";
import detailedProjects from "../assets/info_json/detailedProjects.json";
import { useEffect, useState } from "react";
import ImageCarousel from "../components/imageCarousel";

function DetailedProjects() {
  const { project_id } = useParams();

  const emptyProject = {
    id: "proyecto-1",
    title: "Obras civiles para cimentación de tanques gas natural.",
    labor: "Contratista constructor.",
    date: "Noviembre de 2013 a diciembre de 2013.",
    contractor: "SCHOTT ENVASES FARMACEUTICOS",
    "gallery":["/assets/img/mw_logo.png"]
  };

  const [projectInfo, setProjectInfo] = useState({
    id: "proyecto-1",
    title: "Obras civiles para cimentación de tanques gas natural.",
    labor: "Contratista constructor.",
    date: "Noviembre de 2013 a diciembre de 2013.",
    contractor: "SCHOTT ENVASES FARMACEUTICOS",
    "gallery":["/assets/img/mw_logo.png"]
  });
  useEffect(() => {
    const selectedProject =
    detailedProjects.find((myId) => myId.id === project_id) ||
      emptyProject;
    setProjectInfo(selectedProject);
  }, [project_id]);
  return (
    <>
      <LandingNavBar />
      <CideinContainer>
        <Grid columns={{sm: "2", md: "2", lg:"2", gl:"1", xsm:"2"}} gap={{ sm: "0", md: "0", lg: "0", gl:"0" }}>
          <div className="project_description">
          <div className="project_texts">
          <h2>{projectInfo.title}</h2>
            <p>Labor realizada: {projectInfo.labor}</p>
            <p>Cliente: {projectInfo.contractor}</p>
            <p>Fecha de realización: {projectInfo.date}</p>
          </div>
          </div>
          <div className="imageCarousel_container">
            <ImageCarousel gallery={projectInfo.gallery}/>
          </div>
        </Grid>
      </CideinContainer>
      <CideinFooter />
    </>
  );
}

export default DetailedProjects;
