import { Link } from "react-router-dom";
import BannerImage from "../assets/img/banner_1.png";
import MwWho from "../assets/img/mw_who.png";
//COMPONENTS
import LandingNavBar from "../components/landig_navbar";
import Banner from "../components/landing_banner";
import Grid from "../components/grid";
import LandingCard from "../components/landing_card";
import CideinContainer from "../components/general_section";
import CideinFooter from "../components/cidein_footer";
import MwMockImage from "../assets/img/mock_image.png";

//INFO
import HeaderTitle from "../components/header-title";
import ValuesCard from "../components/values_card";

function SobreNosotros() {
  return (
    <>
      <LandingNavBar />
      <HeaderTitle text="SOBRE NOSOTROS" image={BannerImage} />

      <CideinContainer>
        <h1>¿QUÍENES SOMOS?</h1>
        <div className="quienes_container">
          <div className="quienes_info">
            <div className="quienes_card">
            <h1>MW INGENIERIA SAS</h1>
            <p>
              Somos una empresa comprometida con la construcción de obras
              civiles, enmarcada en la mejora continua, fieles a nuestros
              valores garantizando los recursos necesarios para el cumplimiento
              de nuestros proyectos. Contribuyendo al desarrollo regional y
              nacional, con la integración de profesionales, capacitados a muy
              altos niveles y encausados a ser equipo para aportar soluciones
              correctas y certeras a los proyectos que realizamos, los cuales se
              destacan por su calidad e identidad propia, debido a esto nos
              sentimos fortalecidos para continuar sirviendo no solo en el
              ámbito nacional, sino también internacional.
            </p>
            </div>
          </div>
          <div className="quienes_image">
            <img src={MwWho} alt="Quienes somos" />
          </div>
        </div>
        {/* Nuestros valores */}
        <h1>NUESTROS VALORES</h1>
        <Grid columns={{sm: "2", md: "2", lg:"2", gl:"1", xsm:"2"}} gap={{ sm: "12", md: "12", lg: "12", gl:"32" }}>
          <div className="left flex flex_sm_colum gap_sm_12 gap_md_12 gap_lg_12 gap_12">
            <ValuesCard title="MISIÓN" theme="primary_theme">
              <p>Proveer servicios integrales en diversos campos de la ingeniería
              civil, apoyado en un equipo humano altamente calificado para
              satisfacer las necesidades de nuestros clientes mediante un
              producto optimo entre calidad y servicio.</p>
            </ValuesCard>
            <ValuesCard title="VISIÓN" theme="secondary_theme">
            <p>Ser una empresa líder a nivel nacional e internacional reconocida por su calidad, seguridad, cumplimiento y ofreciendo soluciones integrales en Interventoría, Consultoría, Diseño, y Construcción de Proyectos de Ingeniería Civil.</p>
            </ValuesCard>
          </div>
          <div className="right">
          <ValuesCard title="OBJETIVOS" theme="secondary_theme">
            <ul>
                <li><p>Hacer las cosas bien desde el principio y cada vez mejor.</p></li>
                <li><p>Satisfacer las necesidades locales nacionales e internacionales en el área de la ingeniería civil.</p></li>
                <li><p>El mejoramiento continuo, llevando a buen fin todos los proyectos en los que participamos con responsabilidad, entrega, dedicación, convencimiento y garantía que caracteriza, a MW INGENIERIA S.A.S</p></li>
                <li><p>EN MW IINGENIERÍA SAS nos esforzamos cada día en ofrecer mejores productos y servicios a nuestros clientes. Para ello hemos adoptado nuestra organización con un solo propósito, usted.</p></li>
            </ul>
            </ValuesCard>
          </div>
        </Grid>
      </CideinContainer>
      <CideinFooter />
    </>
  );
}

export default SobreNosotros;
